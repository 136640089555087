import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    heroPanel: {
        margin: '64px 15px 60px 40px',
        '@media print': {
            margin: '0 10px 5px 10px',
        }
     },
     heroTitleText: {
        fontSize: '40px',
        margin: '0 0 15px 0',
     },
     heroDescriptionText: {
        color: '#7f7f7f',
        fontFamily: 'Lyon Text,serif',
        fontSize: '20px',
        lineHeight: '29px',
        '& a': {
            color: '#7f7f7f',
            textDecoration: 'underline',
        }
     },
}));

function HeroPanel(props) {
    const classes = useStyles();

    return(
        <div className={classes.heroPanel} >
            <Typography variant='subtitle1' >
                YOU'RE MAKING A REFERRAL FOR
            </Typography>
            <Typography variant='h3' className={classes.heroTitleText} >
                Care at Home
            </Typography>
            { props.showMessages ? (
                    <>
                        <Typography className={classes.heroDescriptionText}>
                            Use this form when you need home health services: 
                            Home Health Care, DME & Medical Supplies, Home & Vehicle Modifications, 
                            and Prosthetics & Orthotics. 
                            If this doesn't sound like your case, you can go back 
                            and <a href="https://referrals.paradigmcorp.com/">choose a different referral type</a>.
                        </Typography>
                        <br></br>
                        <Typography className={classes.heroDescriptionText}>
                            Please fill out the form with as much case information as possible and submit online. 
                            If you'd prefer, you can talk to our referral team directly: 
                            (855) 223-2228. You can also <a target="blank" href="https://portal.homecareconnect.com/ParadigmCareAtHomeReferralForm.pdf" >download the form</a> and
                            fax it to (855) 625-9553 or email it to <a target="blank" href="mailto:referral-intake@paradigmcorp.com?subject=Care at Home Referral Online">
                                referral-intake@paradigmcorp.com</a>.
                        </Typography>
                    </>
                ) : ''
            }
        </div>
    );
}

export { HeroPanel };
